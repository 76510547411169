import { useRouter } from 'next/router';
import type { Dispatch, FunctionComponent, SetStateAction } from 'react';
import React, { useEffect, useRef, useState } from 'react';
import TagManager from 'react-gtm-module';
import type { Garage } from 'src/models';

import {
  ButtonPrimary,
  InlineCritizr,
  Icon,
  Link,
} from 'src/components/shared';
import type { IconType } from 'src/components/shared/Icon/types';
import { Container } from 'src/components/ui';

import styles from './HeaderMenu.module.scss';
import HeaderMenuEspacePro from './HeaderMenuEspacePro';
import HeaderMenuGuide, { gtmHeaderMenuItemClick } from './HeaderMenuGuide';
import HeaderMenuPrestation from './HeaderMenuPrestation';
import HeaderMenuReseau from './HeaderMenuReseau';
import MenuItemWrapper from './MenuItemWrapper';

import type { FeedbackStatsModel } from 'src/models/feedback/feedbackStats.model';
import type { MenuItem, TagElement } from 'src/models/menu/menu.model';

import {
  getDataTestId,
  mergeClassNames,
  usePrevious,
} from 'src/utils/ReactUtils';
import { disableScroll, enableScroll } from 'src/utils/WindowUtils';

import { useFlag } from 'flags/client';

export const gtmGaragesClick = () => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'eventGA',
      eventCategory: 'menu',
      eventAction: 'clic cta',
      eventLabel: 'nos garages',
    },
  });
};

const gtmClicToOpenDevis = () => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'eventGA',
      eventCategory: 'menu',
      eventAction: 'clic cta',
      eventLabel: 'devis & rdv en ligne',
    },
  });
};

const gtmMenuClick = (tagElement: TagElement | undefined) => {
  TagManager.dataLayer({
    dataLayer: tagElement,
  });
};

type HeaderMenuProps = {
  isOpen?: boolean;
  openSearchEngine: () => void;
  data: MenuItem[];
  setForceBlur: Dispatch<SetStateAction<boolean>>;
  menuVisible: boolean;
  feedBackStats: FeedbackStatsModel;
  garage?: Garage | null;
  isNewsBannerEnabled?: boolean;
};

type MenuId =
  | 'entretien_voiture'
  | 'reparation_carousserie'
  | 'guides'
  | 'reseau'
  | 'espace_pro';

const HeaderMenu: FunctionComponent<HeaderMenuProps> = ({
  isOpen,
  openSearchEngine,
  data,
  setForceBlur,
  menuVisible,
  feedBackStats,
  garage,
  isNewsBannerEnabled,
}) => {
  const [flag, onFlagClick] = useFlag('header-mes-pneus');

  const [menuActive, setMenuActive] = useState<MenuId | ''>('');
  const menuList = useRef<HTMLUListElement | null>(null);
  const subMenusRefs = {
    entretien_voiture: useRef<HTMLDivElement | null>(null),
    reparation_carousserie: useRef<HTMLDivElement | null>(null),
    guides: useRef<HTMLDivElement | null>(null),
    reseau: useRef<HTMLDivElement | null>(null),
    espace_pro: useRef<HTMLDivElement | null>(null),
  };
  const [isMenuHovered, setIsMenuHovered] = useState(false);
  const router = useRouter();

  const prevMenuVisible = usePrevious(menuVisible);
  const prevMenuActive = usePrevious(menuActive);

  const currentMenuActive = menuActive || prevMenuActive;
  const currentSubMenuRef = currentMenuActive
    ? subMenusRefs[currentMenuActive]?.current
    : undefined;

  useEffect(() => {
    setIsMenuHovered(false);
    setMenuActive('');
  }, [router.asPath]);

  useEffect(() => {
    const menu = menuList.current;

    // Primary menu changed visibility
    if (
      prevMenuVisible !== undefined &&
      prevMenuVisible !== menuVisible &&
      menu
    ) {
      if (menuVisible) {
        disableScroll(menu);
      } else {
        enableScroll(menu);
      }
    }

    // Sub menu changed visibility
    if (
      prevMenuActive !== undefined &&
      prevMenuActive !== menuActive &&
      currentSubMenuRef
    ) {
      if (menuActive) {
        // Make sure to impact primary menu scroll lock
        if (menu) {
          enableScroll(menu);
        }

        disableScroll(currentSubMenuRef);
      } else {
        enableScroll(currentSubMenuRef);
      }
    }

    // Clean locks on unmount
    return () => {
      if (currentSubMenuRef) {
        enableScroll(currentSubMenuRef);
      }

      if (menu) {
        enableScroll(menu);
      }
    };
  }, [
    prevMenuActive,
    menuActive,
    currentSubMenuRef,
    prevMenuVisible,
    menuVisible,
  ]);

  const buildMenuLevel2ByType = (menuItem: MenuItem) => {
    if (!('type' in menuItem)) {
      return null;
    }

    if (
      menuItem.type === 'entretien_voiture' ||
      menuItem.type === 'reparation_carousserie'
    ) {
      return (
        <HeaderMenuPrestation
          typePrestation={menuItem.type}
          items={menuItem.child}
          banner={menuItem.banner}
          isBannerTitle={menuItem.type === 'entretien_voiture'}
          openSearchEngine={openSearchEngine}
          subMenuRef={subMenusRefs[menuItem.type]}
          garage={garage}
        />
      );
    }

    if (menuItem.type === 'guides') {
      return <HeaderMenuGuide items={menuItem.child} />;
    }

    if (menuItem.type === 'reseau') {
      return (
        <HeaderMenuReseau
          items={menuItem.child}
          subMenuRef={subMenusRefs[menuItem.type]}
        />
      );
    }

    if (menuItem.type === 'espace_pro') {
      return (
        <HeaderMenuEspacePro
          items={menuItem.child}
          subMenuRef={subMenusRefs[menuItem.type]}
        />
      );
    }

    return null;
  };

  const getTitle = (menuItem: MenuItem) => {
    if (menuItem.title === 'Changer vos pneus' && flag) {
      return flag;
    }

    return menuItem.title;
  };

  const clickMenuToggle = (menuItem: MenuItem) => {
    setMenuActive(menuItem.id as MenuId);
    const menuTitle = getTitle(menuItem);
    if (menuItem.url != null && !menuItem.url && menuItem.url !== '') {
      gtmHeaderMenuItemClick(menuTitle);
    }

    if (menuList.current) {
      menuList.current.scrollTop = 0;
    }
  };

  const handleOnMenuClick = (menuItem: MenuItem) => () => {
    if ('tagElements' in menuItem && menuItem.tagElements) {
      gtmMenuClick(menuItem.tagElements);
    } else {
      clickMenuToggle(menuItem);
    }

    const menuTitle = getTitle(menuItem);
    if (menuTitle === flag) {
      onFlagClick();
    }
  };

  const handleShowMenu = (menuItem: MenuItem) => () => {
    if (menuItem.child) {
      setIsMenuHovered(true);
      setMenuActive(menuItem.id as MenuId);
      setForceBlur(true);
    }
  };

  const handleHideMenu = () => {
    setForceBlur(false);
    setIsMenuHovered(false);
    setMenuActive('');
  };
  const menuItems = data.map((menuItem, index) => {
    const title = getTitle(menuItem);

    const idNetworkBase = menuItem.url?.match(/id_network_base=(\d+)/)?.[1];
    const shouldPerformSEOTrick = idNetworkBase;

    return (
      <MenuItemWrapper
        key={index}
        className={mergeClassNames([
          'globalCssClass' in menuItem && menuItem.globalCssClass,
          styles.item,
          'customCssClass' in menuItem &&
            menuItem.customCssClass &&
            styles[menuItem.customCssClass],
          menuItem.child && styles.menuHasChildren,
        ])}
        handleShowMenu={handleShowMenu(menuItem)}
        handleHideMenu={handleHideMenu}
        {...getDataTestId({ dataTestId: menuItem.id, prefix: 'menu_item' })}
      >
        {menuItem.url ? (
          <Link
            title={title}
            className={mergeClassNames([
              styles.entry,
              'color' in menuItem &&
                menuItem.color &&
                styles['entry' + menuItem.color],
            ])}
            href={
              !shouldPerformSEOTrick
                ? menuItem.url
                : menuItem.url
                    .replace('id_network_base=' + idNetworkBase, '')
                    // If there are other query parameters, don't break the chain
                    .replace('?&', '?')
                    // Remove trailing "?"
                    .replace(/\?$/, '')
            }
            onClick={(e) => {
              if (shouldPerformSEOTrick) {
                e.preventDefault();

                window.location.href = menuItem.url;
              }

              handleOnMenuClick(menuItem);
            }}
            legacy={!!garage}
            {...getDataTestId({ dataTestId: menuItem.id })}
          >
            <Icon
              name={menuItem.icon as IconType}
              height={50}
              width={50}
              className={`d-lg-none ${styles.itemIcon}`}
              color="inherit"
            />
            <span className={styles.itemDesc}>{title}</span>
            <Icon
              name="fleche-d"
              height={16}
              width={16}
              className={`d-lg-none ${styles.itemArrow}`}
            />
          </Link>
        ) : (
          <div
            title={title}
            className={mergeClassNames([
              styles.entry,
              'color' in menuItem &&
                menuItem.color &&
                styles['entry' + menuItem.color],
            ])}
            onClick={handleOnMenuClick(menuItem)}
          >
            <Icon
              name={menuItem.icon as IconType}
              height={50}
              width={50}
              className={`d-lg-none ${styles.itemIcon}`}
            />
            <span className={styles.itemDesc}>{title}</span>
            <Icon
              name="fleche-d"
              height={16}
              width={16}
              className={`d-lg-none ${styles.itemArrow}`}
            />
          </div>
        )}
        {menuItem.child && (
          <div
            className={mergeClassNames([
              styles.submenu,
              menuActive === menuItem.id ? styles.submenuActive : '',
              isMenuHovered && menuActive === menuItem.id
                ? styles.subMenuHovered
                : '',
            ])}
          >
            <div
              className={`d-lg-none ${styles.submenuHeader}`}
              onClick={() => setMenuActive('')}
            >
              <Icon
                name="fleche-g"
                className={styles.itemArrow}
                height={16}
                width={16}
              />
              <Icon
                name={menuItem.icon as IconType}
                height={50}
                width={50}
                className={styles.itemIcon}
              />
              <span className={styles.itemDesc}>{title}</span>
            </div>
            {buildMenuLevel2ByType(menuItem)}
          </div>
        )}
      </MenuItemWrapper>
    );
  });

  return (
    <React.Fragment>
      <nav
        className={mergeClassNames([
          styles.menu,
          isOpen && styles.menuOpen,
          isNewsBannerEnabled && styles.menuWithBanner,
        ])}
      >
        <Container fluid className={`d-lg-none ${styles.btns}`}>
          {(!garage || garage?.hasDevis) && (
            <ButtonPrimary
              type="button"
              className={styles.btnsItem}
              onClick={() => {
                gtmClicToOpenDevis();
                openSearchEngine();
              }}
            >
              <Icon
                name="recherche"
                className={styles.btnsItemIcon}
                width={32}
                height={32}
              />
              Devis & RDV
            </ButtonPrimary>
          )}
          {!garage && (
            <Link
              className={`btn ${styles.btnsItem} ${styles.btnsItemWhite}`}
              href="/garage-auto"
              onClick={() => {
                gtmGaragesClick();
              }}
              data-testid="menu_item_reparateur_auto_mobile"
            >
              <Icon
                name="mob-recherche-garage"
                className={styles.btnsItemWhiteIcon}
                color="inherit"
                width={32}
                height={32}
              />
              Nos garages
            </Link>
          )}
        </Container>
        <ul
          ref={menuList}
          className={mergeClassNames([
            styles.items,
            menuActive && styles.itemsNotVisible,
          ])}
          data-testid="menu_items"
        >
          {menuItems}
          {garage && (
            <>
              {feedBackStats.countReview > 0 ? (
                <InlineCritizr
                  totalRatings={feedBackStats.countReview}
                  className={styles.feedBack}
                  averageRating={feedBackStats.reviewNote}
                  txtRatingColor={'Brown'}
                  starsPosition={'right'}
                  actorId={garage.garIdNetwork}
                />
              ) : (
                <div className={styles.noFeedBack}>
                  {"Pas encore d'avis pour ce garage"}
                </div>
              )}
            </>
          )}
        </ul>
      </nav>
    </React.Fragment>
  );
};

export default HeaderMenu;
