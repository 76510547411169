import type { FunctionComponent } from 'react';
import React, { useState } from 'react';
import TagManager from 'react-gtm-module';

import { Icon, InlineCritizr, Link } from 'src/components/shared';
import Responsive from 'src/components/shared/Responsive/Responsive';
import { Col, Container, Row } from 'src/components/ui';

import type { Garage } from '../../../../../models';
import style from './Footer.module.scss';

import type { CityModel } from 'src/models/city.model';
import type { DepartmentModel } from 'src/models/department/department.model';
import type { FeedbackStatsModel } from 'src/models/feedback/feedbackStats.model';

import { mergeClassNames } from 'src/utils/ReactUtils';
import { capitalizeFirstLetter } from 'src/utils/StringUtils';

const RATING_TEXT = 'Notation basée sur les données recueillies pour Ad.fr';
const LINKS = [
  { txtUrl: 'Présentation', linkUrl: '/presentation' },
  {
    txtUrl: "Nos offres d'emploi",
    linkUrl: 'https://taleez.com/careers/reseauad',
  },
  {
    txtUrl: 'Rejoindre le réseau',
    linkUrl: '/espace-professionnel/nous-rejoindre',
  },
] as const;

const gtmClickEvent = (eventLabel: string) => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'eventGA',
      eventCategory: 'footer',
      eventAction: 'clic cta',
      eventLabel,
    },
  });
};

export type FooterProps = {
  data: {
    cities: CityModel[];
    departments: DepartmentModel[];
    feedbackStats: FeedbackStatsModel;
  };
  className?: string;
  garage: Garage | null | undefined;
};

const Footer: FunctionComponent<FooterProps> = ({
  data: { cities, departments, feedbackStats },
  className,
  garage,
}) => {
  const [showAllCities, setShowAllCities] = useState(false);
  const [showAllDepartments, setShowAllDepartments] = useState(false);

  if (!cities?.length || !departments?.length) {
    return null;
  }

  const renderListLinks = LINKS.map((link, index) => {
    return (
      <li key={index}>
        <Link
          className={style.linksItem}
          href={link.linkUrl}
          onClick={() => gtmClickEvent(link.linkUrl)}
        >
          {link.txtUrl}
        </Link>
      </li>
    );
  });

  const renderCities = (citiesToRender: CityModel[], isVisible = true) =>
    citiesToRender?.map((city: CityModel, index) => {
      return (
        <Link
          key={index}
          className={mergeClassNames([
            style.citiesLink,
            !isVisible && style.hide,
          ])}
          href={city.url}
          title={`Garage ${capitalizeFirstLetter(city.name)}`}
          onClick={() =>
            gtmClickEvent(`Garage ${capitalizeFirstLetter(city.name)}`)
          }
        >
          <p className={`${style.citiesTitle}`}>
            {' '}
            Garage {capitalizeFirstLetter(city.name)}{' '}
          </p>
        </Link>
      );
    });

  const renderDepartments = (
    departmentsToRender: DepartmentModel[],
    isVisible = true,
  ) =>
    departmentsToRender?.map((department: DepartmentModel, index) => {
      return (
        <Link
          key={index}
          className={mergeClassNames([
            style.citiesLink,
            !isVisible && style.hide,
          ])}
          href={department.url}
          title={`Garage ${capitalizeFirstLetter(department.name)}`}
          onClick={() =>
            gtmClickEvent(`Garage ${capitalizeFirstLetter(department.name)}`)
          }
        >
          <p className={`${style.citiesTitle}`}>
            {' '}
            Garage {capitalizeFirstLetter(department.name)}{' '}
          </p>
        </Link>
      );
    });

  const toggleCities = () => setShowAllCities(!showAllCities);
  const toggleDepartments = () => setShowAllDepartments(!showAllDepartments);

  return (
    <footer className={`${className} ${style.footer}`}>
      <Container className={style.wrapper}>
        <Row>
          <InlineCritizr
            averageRating={feedbackStats.reviewNote}
            totalRatings={feedbackStats.countReview}
            txtRating={RATING_TEXT}
            actorId={garage?.garIdNetwork}
            hasLogo
            dataTestId="footer_avis_critizr"
          />
        </Row>
        <Row>
          <hr className={style.separator} />
        </Row>
        <Row>
          <Col sm={12} md className={style.links}>
            <ul className={style.links} data-testid="footer_menu_links">
              {renderListLinks}
              <li>
                <Link
                  className={style.linksItem}
                  href="https://www.idgarages.pro"
                  target="_blank"
                  onClick={() => gtmClickEvent('Espace Garage')}
                >
                  Espace Garage
                </Link>
              </li>
              <li>
                <Link
                  className={style.linksItem}
                  href="/garage-auto/liste-de-garages"
                  target="_blank"
                  rel="noreferrer"
                >
                  Tous nos garages
                </Link>
                <hr className={`${style.separator} ${style.separatorList}`} />
              </li>
              <li>
                <Link className={style.linksItem} href="/contact">
                  Nous contacter
                </Link>
                <hr className={`${style.separator} ${style.separatorList}`} />
              </li>
              <li>
                <Link className={style.linksItem} href="/accueil-newsletter">
                  Newsletter
                </Link>
                <hr className={`${style.separator} ${style.separatorList}`} />
              </li>
              <li>
                <Link
                  className={style.linksItem}
                  href="https://adcarrosserie-pro.ad.fr"
                  target="_blank"
                  onClick={() => gtmClickEvent('Espace Ad Pro')}
                >
                  RDV AD PRO CARROSSERIE
                </Link>
                <hr className={`${style.separator} ${style.separatorList}`} />
              </li>
            </ul>
          </Col>
          <Col
            sm={12}
            md
            className={style.cities}
            data-testid="footer_top_cities"
          >
            <Responsive is={'mobile'}>
              <p className={style.title} onClick={toggleCities}>
                Garage AD en ville{' '}
                <Icon
                  color={'white'}
                  name={showAllCities ? 'fleche-h' : 'fleche-b'}
                  width={24}
                  height={24}
                />{' '}
              </p>
              {renderCities(cities, showAllCities)}
            </Responsive>
            <Responsive is={'desktop'}>
              <p className={style.title}> Garage AD en ville </p>
              {renderCities(cities.slice(0, 10))}
              {renderCities(
                cities.slice(10),
                showAllCities && cities.length > 10,
              )}
              <div
                className={mergeClassNames([style.citiesLink, style.showMore])}
                onClick={toggleCities}
              >
                {`Voir ${showAllCities ? 'moins' : 'plus'} de garages `}
                <Icon
                  color={'white'}
                  name={showAllCities ? 'fleche-h' : 'fleche-b'}
                  width={24}
                  height={24}
                />
              </div>
            </Responsive>
            <hr
              className={mergeClassNames([
                style.separator,
                style.uniqueSeparator,
              ])}
            />
          </Col>
          <Col
            sm={12}
            md
            className={style.cities}
            data-testid="footer_top_departments"
          >
            <Responsive is={'mobile'}>
              <p className={style.title} onClick={toggleDepartments}>
                Dans votre département{' '}
                <Icon
                  color={'white'}
                  name={showAllDepartments ? 'fleche-h' : 'fleche-b'}
                  width={24}
                  height={24}
                />{' '}
              </p>
              {renderDepartments(departments, showAllDepartments)}
            </Responsive>
            <Responsive is={'desktop'}>
              <p className={style.title}> Dans votre département </p>
              {renderDepartments(departments.slice(0, 10))}
              {renderDepartments(
                departments.slice(10),
                showAllDepartments && departments.length > 10,
              )}
              <div
                className={mergeClassNames([style.citiesLink, style.showMore])}
                onClick={toggleDepartments}
              >
                {`Voir ${showAllDepartments ? 'moins' : 'plus'} de garages `}
                <Icon
                  color={'white'}
                  name={showAllDepartments ? 'fleche-h' : 'fleche-b'}
                  width={24}
                  height={24}
                />
              </div>
            </Responsive>
          </Col>
        </Row>
      </Container>
      <div className={style.mentions} data-testid="footer_mentions">
        <Link href="/plan-du-site" className={style.mentionsLink}>
          Plan du site
          <span className={style.separatorDash}>{' - '}</span>
        </Link>
        <Link
          href="/mentions-legales"
          className={style.mentionsLink}
          onClick={() => gtmClickEvent('Mentions légales et CGUV')}
        >
          Mentions légales et CGUV
          <span className={style.separatorDash}>{' - '}</span>
        </Link>
        <Link
          href="/accueil-charte-de-confidentialite"
          className={style.mentionsLink}
          onClick={() => gtmClickEvent('Protection des données personnelles')}
        >
          Protection des données personnelles
          <span className={style.separatorDash}>{' - '}</span>
        </Link>
        <button
          id="ot-sdk-btn"
          className={mergeClassNames([
            'ot-sdk-show-settings',
            style.mentionsLink,
          ])}
          onClick={() => gtmClickEvent('Gérer mes Cookies')}
        >
          Gérer mes cookies
        </button>
        <Link
          href="https://www.partsholdingeurope.com/"
          className={style.mentionsLink}
          onClick={() => gtmClickEvent('Sites du groupe')}
        >
          <span className={style.separatorDash}>{' - '}</span>
          Sites du groupe
        </Link>
        <div className={style.copyright}>
          © Autodistribution {new Date().getFullYear()}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
