import { useRouter } from 'next/router';
import type { FunctionComponent, MutableRefObject } from 'react';
import React from 'react';
import TagManager from 'react-gtm-module';

import {
  ButtonPrimary,
  Link,
  Image,
  ButtonTertiary,
  Icon,
} from 'src/components/shared';
import type { IconType } from 'src/components/shared/Icon/types';

import type { Garage } from '../../../../../../models';
import styles from './HeaderMenu.module.scss';
import { gtmHeaderMenuItemClick } from './HeaderMenuGuide';

import type { BannerReparation } from 'src/models/menu/menu.model';
import type { MenuPrestationModel } from 'src/models/menu/menuPrestation.model';
import type { MenuPromotionBannerModel } from 'src/models/menu/menuPromotionBanner.model.';

const gtmHeaderPromoClick = (
  promoNameArr: string | undefined,
  promoPositionArr: number | undefined,
) => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'promoClick',
      promoNameArr,
      promoPositionArr,
    },
  });
};

const gtmClickPresta = (typePresta: string) => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'eventGA',
      eventCategory: 'menu',
      eventAction: typePresta,
      eventLabel: 'Devis et rendez-vous',
    },
  });
};

const MAX_PRESTATION_ENTRETIEN = 24;

type HeaderMenuPrestationProps = {
  typePrestation: 'entretien_voiture' | 'reparation_carousserie';
  items: MenuPrestationModel[];
  banner: MenuPromotionBannerModel | BannerReparation | null;
  isBannerTitle: boolean;
  openSearchEngine: () => void;
  subMenuRef: MutableRefObject<HTMLDivElement | null>;
  garage: Garage | null | undefined;
};

const HeaderMenuPrestation: FunctionComponent<HeaderMenuPrestationProps> = ({
  items,
  typePrestation,
  banner,
  isBannerTitle,
  openSearchEngine,
  subMenuRef,
  garage,
}) => {
  const router = useRouter();

  const typePresta =
    typePrestation === 'entretien_voiture'
      ? 'Entretenir votre voiture'
      : 'Réparation carrosserie';
  const titleType =
    typePrestation === 'entretien_voiture' ? 'mécaniques' : 'de carrosserie';

  const garagePath = router.asPath.split('/').slice(0, 3).join('/');
  const shouldPerformSEOTrick = !!garage;

  const buildPrestationEntretienItems = (
    prestationEntretienItem: MenuPrestationModel,
  ) => {
    return (
      <li key={prestationEntretienItem.id} className={styles.listItem}>
        <Link
          className={styles.listLink}
          href={
            // SEO trick to avoid linking to the prestation page of the garage
            !shouldPerformSEOTrick
              ? prestationEntretienItem.targetUrl
              : prestationEntretienItem.targetUrl.replace(garagePath, '')
          }
          legacy={!!garage || prestationEntretienItem.legacy}
          onClick={(e) => {
            if (shouldPerformSEOTrick) {
              e.preventDefault();

              window.location.href = prestationEntretienItem.targetUrl;
            }

            gtmHeaderMenuItemClick(typePresta, prestationEntretienItem.title);
          }}
        >
          <Icon
            name={prestationEntretienItem.icon as IconType}
            color="inherit"
            className={styles.listIcon}
            width={25}
            height={25}
          />
          <span className={styles.listEntry}>
            {prestationEntretienItem.title}
          </span>
          <Icon
            name="fleche-d"
            className={styles.listArrow}
            height={12}
            width={12}
          />
        </Link>
      </li>
    );
  };

  const shouldAddViewAllLink = () => {
    if (garage) {
      return garage?.hasDevis && typePrestation === 'entretien_voiture';
    }

    return typePrestation === 'entretien_voiture';
  };

  const prestationEntretienList = [
    ...items
      .slice(
        0,
        MAX_PRESTATION_ENTRETIEN -
          (items.length >= MAX_PRESTATION_ENTRETIEN ? 1 : 0),
      )
      .map((prestationEntretienItem) =>
        buildPrestationEntretienItems(prestationEntretienItem),
      ),
    shouldAddViewAllLink() &&
      buildPrestationEntretienItems({
        id: 999,
        title: 'Voir toutes les prestations',
        order: 999,
        targetUrl: garage ? `${garagePath}/prestation` : '/prestation',
        legacy: true,
        icon: 'plus-de-prestations',
        isMeca: false,
        isCaros: false,
      }),
  ].filter(Boolean);

  const promotionBannerEntretien = banner ? (
    <div className={'d-flex flex-column'}>
      <div className={styles.imageContainer}>
        <Image
          width={365}
          height={typePrestation === 'entretien_voiture' ? 234 : 147}
          src={banner.icon}
          alt={banner.alt}
          className={styles.bannerImg}
        />
      </div>
      <ButtonPrimary
        type="button"
        rightIcon="fleche-d"
        url={banner.url}
        className={styles.bannerBtn}
        onClick={() => gtmHeaderPromoClick(banner.title, banner.order)}
      >
        {banner.isPromo ? "J'en profite" : 'Je découvre'}
      </ButtonPrimary>
    </div>
  ) : null;
  return (
    <div className={styles.itemContainer} ref={subMenuRef}>
      <div>
        <div className={styles.itemTitle}>
          {`Découvrez les prestations ${titleType}`}
          {garage?.businessName
            ? ` de notre ${garage?.businessName}`
            : ' disponibles dans nos garages'}
        </div>
        <ul className={styles.itemList}>{prestationEntretienList}</ul>
        {(!garage || garage?.hasDevis) && (
          <>
            <ButtonTertiary
              type="button"
              color="Brown"
              className="d-none d-lg-block area-open-devis"
              onClick={() => {
                gtmClickPresta(typePresta);
                openSearchEngine();
              }}
            >
              Devis et rendez-vous en ligne
            </ButtonTertiary>
            <ButtonTertiary
              type="button"
              className={`d-lg-none ${styles.submenuBtn}`}
              leftIcon="recherche"
              onClick={openSearchEngine}
            >
              Devis et rendez-vous
            </ButtonTertiary>
          </>
        )}
      </div>
      <div className={'d-none d-lg-block'}>
        {isBannerTitle && banner && (
          <div className={styles.bannerTitle}>En ce moment</div>
        )}
        {promotionBannerEntretien}
      </div>
    </div>
  );
};
export default HeaderMenuPrestation;
