import type { FunctionComponent, MutableRefObject } from 'react';
import React, { useState } from 'react';

import { Link, Image, Icon } from 'src/components/shared';
import type { IconType } from 'src/components/shared/Icon/types';

import styles from './HeaderMenu.module.scss';
import { gtmHeaderMenuItemClick } from './HeaderMenuGuide';

import type { MenuEspacePro } from 'src/models/menu/menu.model';

import { mergeClassNames } from 'src/utils/ReactUtils';

type HeaderMenuEspaceProProps = {
  items: MenuEspacePro;
  subMenuRef: MutableRefObject<HTMLDivElement | null>;
};

const HeaderMenuEspacePro: FunctionComponent<HeaderMenuEspaceProProps> = ({
  items,
  subMenuRef,
}) => {
  const [menuActive, setMenuActive] = useState(-1);

  const renderCardsList = items.map((item, index) => {
    return (
      <li key={index} className={styles.card}>
        <figure
          className={styles.cardContainer}
          onClick={() => setMenuActive(index)}
        >
          <div className={styles.pictureWrapper}>
            <Image
              width={263}
              height={133}
              src={item.image}
              alt={item.alt}
              className={styles.cardPicture}
            />
          </div>
          <div className={styles.cardCat}>
            <Icon
              name={item.icon as IconType}
              width={45}
              height={45}
              className={styles.cardIcon}
            />
            <figcaption className={styles.cardTitle}>{item.label}</figcaption>
          </div>
          <Icon
            name="fleche-d"
            height={16}
            width={16}
            className={`d-lg-none ${styles.itemArrow}`}
          />
        </figure>
        <p className={`d-none d-lg-block ${styles.cardDesc}`}>
          {item.description}
        </p>
        <div
          className={mergeClassNames([
            styles.cardSublist,
            menuActive === index && styles.cardSublistActive,
          ])}
        >
          <div className={`d-lg-none ${styles.cardHeader}`}>
            <Icon
              name="fleche-g"
              height={16}
              width={16}
              className={`d-lg-none ${styles.itemArrow}`}
              onClick={() => setMenuActive(-1)}
            />
            <div className={'d-flex flex-column'}>
              <figure
                className={`${styles.cardContainer}`}
                onClick={() => setMenuActive(-1)}
              >
                <div className={`${styles.pictureWrapper}`}>
                  <Image
                    width={263}
                    height={133}
                    src={item.image}
                    alt={item.alt}
                    className={styles.cardPicture}
                  />
                </div>
                <div className={styles.cardCat}>
                  <Icon
                    name={item.icon as IconType}
                    width={48}
                    height={48}
                    className={styles.cardIcon}
                  />
                  <figcaption className={styles.cardTitle}>
                    {item.label}
                  </figcaption>
                </div>
              </figure>
              <p className={styles.cardDesc}>{item.description}</p>
            </div>
          </div>
          <ul className={styles.cardEntries}>
            {item.links.map(
              (link: { label: string; url: string }, i: number) => {
                return (
                  <li key={i} className={styles.cardEntry}>
                    <Link
                      className={styles.cardLink}
                      href={link.url}
                      onClick={() =>
                        gtmHeaderMenuItemClick(
                          'Espace Pro',
                          item.label,
                          link.label,
                        )
                      }
                    >
                      <Icon
                        name="fleche-d"
                        height={12}
                        width={12}
                        className={styles.cardPicto}
                        color={'#830008'}
                      />
                      <span className={styles.cardItem}>{link.label}</span>
                    </Link>
                  </li>
                );
              },
            )}
          </ul>
        </div>
      </li>
    );
  });

  return (
    <div className={styles.itemContainer} ref={subMenuRef}>
      <ul className={styles.cards}>{renderCardsList}</ul>
      <div className={styles.access}>
        <hr className={`d-none d-lg-block ${styles.separator}`} />
        <div className={styles.accessList}>
          <div className={styles.accessTitle}>Accès rapides</div>
          <ul className={styles.accessItems}>
            <li className={styles.accessItem}>
              <Link
                className={styles.accessLink}
                href="https://www.idgarages.pro"
                onClick={() =>
                  gtmHeaderMenuItemClick(
                    'Espace Pro',
                    'Accès rapides',
                    'Mon espace garage',
                  )
                }
              >
                <Icon
                  name="fleche-d"
                  height={12}
                  width={12}
                  className={`d-lg-none ${styles.accessIcon}`}
                />
                <span className={styles.accessDesc}>Mon espace garage</span>
              </Link>
            </li>
            <li className={styles.accessItem}>
              <Link
                className={styles.accessLink}
                href="https://adcarrosserie-pro.ad.fr"
                onClick={() =>
                  gtmHeaderMenuItemClick('Espace Pro', 'Accès rapides', 'Sad')
                }
              >
                <Icon
                  name="fleche-d"
                  height={12}
                  width={12}
                  className={`d-lg-none ${styles.accessIcon}`}
                />
                <span className={styles.accessDesc}>
                  Rdv AD Pro Carrosserie
                </span>
              </Link>
            </li>
            <li
              className={`d-none ${styles.accessItem} ${styles.accessItemUppercase}`}
            >
              <Link
                className={styles.accessLink}
                href="https://www.idgarages.pro"
                onClick={() =>
                  gtmHeaderMenuItemClick(
                    'Espace Pro',
                    'Accès rapides',
                    'Ad pro',
                  )
                }
              >
                <Icon
                  name="fleche-d"
                  height={12}
                  width={12}
                  className={`d-lg-none ${styles.accessIcon}`}
                />
                <span className={styles.accessDesc}>Ad pro</span>
              </Link>
            </li>
            <li className={styles.accessItem}>
              <Link
                className={styles.accessLink}
                href="https://monespace.ad.fr/"
                onClick={() =>
                  gtmHeaderMenuItemClick(
                    'Espace Pro',
                    'Accès rapides',
                    'Mon espace ad',
                  )
                }
              >
                <Icon
                  name="fleche-d"
                  height={12}
                  width={12}
                  className={`d-lg-none ${styles.accessIcon}`}
                />
                <span className={styles.accessDesc}>Mon espace ad</span>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};
export default HeaderMenuEspacePro;
