import { getAdviceCategories } from '../advice/getAdviceCategories';
import { bannerReparation } from './bannerReparation';
import { getMenuPromotionBanner } from './getMenuPromotionBanner';
import { getMenus } from './getMenus';
import { menuEspacePro } from './menuEspacePro';
import { menuReseaux } from './menuReseaux';

import { menuMapper } from 'src/models/menu/menu.model';
import type { MenuPrestationModel } from 'src/models/menu/menuPrestation.model';

export const getMenuData = async () => {
  const [menuData, promotionBanner, adviceCategories] = await Promise.all([
    getMenus(),
    getMenuPromotionBanner(),
    getAdviceCategories(),
  ]);

  const mechanical = menuData
    .find((item) => item.id === 'mechanical')
    ?.children.map((item, index) => menuMapper(item, index));

  const bodywork = menuData
    .find((item) => item.id === 'bodywork')
    ?.children.map((item, index) => menuMapper(item, index));

  const firstLevelMenu = (
    [
      {
        id: 'entretien_voiture',
        title: 'Entretenir votre voiture',
        icon: 'm-reparation',
        type: 'entretien_voiture',
        child: mechanical as MenuPrestationModel[],
        banner: promotionBanner,
        url: null,
      },
      {
        id: 'reparation_carousserie',
        title: 'Réparation carrosserie',
        icon: 'carrosserie',
        type: 'reparation_carousserie',
        child: bodywork as MenuPrestationModel[],
        banner: bannerReparation,
        url: null,
      },
      {
        id: 'changer_pneus',
        title: 'Changer vos pneus',
        icon: 'm-pneu',
        child: null,
        url: '/prestation/pneumatiques',
        tagElements: {
          event: 'eventGA',
          eventCategory: 'menu',
          eventAction: 'Changer vos pneus',
        },
      },
      {
        id: 'voiture_occasion',
        title: "Nos voitures d'occasion",
        icon: 'm-occasion',
        child: null,
        url: '/annonces-vehicules',
        tagElements: {
          event: 'eventGA',
          eventCategory: 'menu',
          eventAction: "Nos voitures d'occasion",
        },
      },
      {
        id: 'guides',
        title: 'Notre guide conseil',
        icon: 'm-conseils',
        type: 'guides',
        child: adviceCategories,
        banner: null,
        url: null,
      },
      {
        id: 'reseau',
        title: 'Notre réseau',
        icon: 'm-reseau',
        child: menuReseaux,
        type: 'reseau',
        url: null,
      },
      promotionBanner?.isPromo
        ? {
            id: 'offres',
            title: 'Nos offres',
            icon: 'm-promo',
            child: null,
            url: '/promotion',
            color: 'Red',
          }
        : null,
      {
        id: 'm-emploi',
        title: 'Recrutement',
        icon: 'm-emploi',
        child: null,
        url: 'https://taleez.com/careers/reseauad',
      },
      {
        id: 'espace_pro',
        title: 'Espace pro',
        icon: 'm-pro',
        child: menuEspacePro,
        type: 'espace_pro',
        url: null,
        globalCssClass: 'ml-auto',
        customCssClass: 'pro',
      },
    ] as const
  ).filter(Boolean);

  return firstLevelMenu as NonNullable<(typeof firstLevelMenu)[number]>[];
};
