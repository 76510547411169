export const menuEspacePro = [
  {
    image: '/images/components/Header/pro-services.jpg',
    alt: 'Nos services aux pros',
    icon: 'm-services-pro',
    label: 'Nos services aux pros',
    description: `Être un réparateur AD c'est être un technicien, un entrepreneur et un commerçant. Alors pour vous aider au quotidien,
      le réseau met à votredisposition un large évantail de services.`,
    links: [
      {
        label: "AD vous apporte la puissance d'un groupe",
        url: '/espace-professionnel/espace-professionnel-le-reseau-ad-vous-apporte-la-puissance-d-un-groupe',
      },
      {
        label: 'Une innovation constante et une notoriété boostée',
        url: '/espace-professionnel/espace-professionnel-le-reseau-ad-innove-constamment-et-booste-votre-notoriete',
      },
      {
        label: 'Un accompagnement au quotidien',
        url: '/espace-professionnel/espace-professionnel-un-accompagnement-au-quotidien',
      },
    ],
  },

  {
    image: '/images/components/Header/pro-commitments.jpg',
    alt: 'Nos engagements aux pros',
    icon: 'm-garanties',
    label: 'Nos engagements aux pros',
    description: `L'ambition du réseau AD est d'être la préférence client pour
      l'entretien et la réparation de leur voiture multimarque. Pour le devenir,
      voici nos engagements.`,
    links: [
      {
        label: 'Découvrez nos engagements aux professionnels',
        url: '/espace-professionnel/espace-professionnel-les-engagements-du-reseau-ad-envers-nos-reparateurs',
      },
    ],
  },

  {
    image: '/images/components/Header/auto-distribution-network.jpg',
    alt: 'Rejoindre le réseau AD',
    icon: 'm-reseau-ad',
    label: 'Rejoindre le réseau AD',
    description: `Le réseau AD est le n°1 en France de la réparation auto multimarques en mécanique comme en carrosserie.
      N'attendez plus et venez nous rejoindre.`,
    links: [
      {
        label: 'Découvrez comment rejoindre notre réseau',
        url: '/espace-professionnel/nous-rejoindre',
      },
    ],
  },
] as const;
