import dynamic from 'next/dynamic';
import type { FunctionComponent } from 'react';
import React from 'react';

import type { Garage } from '../../../models';
import Footer from './components/Footer/Footer';
import Header from './components/Header/Header';
import { QuotationEngineContextProvider } from './components/QuotationEngine/QuotationEngineContext';

import { getTopCities } from 'src/services/cities/getTopCities';
import { getDepartments } from 'src/services/departments/getDepartments';
import { getFeedbackStats } from 'src/services/feedback/getFeedbackStats';
import { getGarageMenuData } from 'src/services/garage/getGarageMenuData';
import { getMenuData } from 'src/services/menu/getMenuData';

import type { MenuItem } from 'src/models/menu/menu.model';

import { usePageViewTracker } from 'src/utils/ReactUtils';

const QuotationEngineDynamic = dynamic(
  () => import('./components/QuotationEngine/QuotationEngine'),
  { ssr: false },
);

export const getPublicLayoutData = async (params?: {
  garageSlug: string;
  garage: Garage;
}) => {
  const [cities, departments, feedbackStats, headerData, headerGarageData] =
    await Promise.all([
      getTopCities(),
      getDepartments(),
      getFeedbackStats(params?.garage.garIdNetwork),
      !params?.garage.garIdNetwork?.length ? getMenuData() : null,
      params?.garage.garIdNetwork
        ? getGarageMenuData(params?.garageSlug, params?.garage.garIdNetwork)
        : null,
    ]);

  return {
    footerData: {
      cities,
      departments,
      feedbackStats,
    },
    headerData: (headerData ?? headerGarageData) as MenuItem[],
    garage: params?.garage ?? null,
    garageSlug: params?.garageSlug ?? null,
    feedbackStats,
  };
};

type PublicLayoutProps = {
  data: Awaited<ReturnType<typeof getPublicLayoutData>>;
};

const PublicLayout: FunctionComponent<PublicLayoutProps> = ({
  children,
  data,
}) => {
  usePageViewTracker();

  return (
    <QuotationEngineContextProvider garageSlug={data.garageSlug}>
      <Header
        feedbackStats={data.feedbackStats}
        garage={data.garage}
        data={data.headerData}
      />
      {children}
      <Footer garage={data.garage} data={data.footerData} />
      <QuotationEngineDynamic />
    </QuotationEngineContextProvider>
  );
};

export default PublicLayout;
