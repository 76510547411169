const icons: Record<string, string> = {
  'boitier-bio-ethanol-homologue': '76',
  'eco-diagnostic-auto': '48',
  'courroie-de-distribution': '25',
  'vanne-egr': '17',
  'diagnostic-electronique': '28',
  'geometrie-et-parallelisme': '16',
  amortisseurs: '12',
  pneumatiques: '22',
  'reparation-plastique-auto': '33',
  echappement: '14',
  'entretien-auto-revision-vidange': '10',
  'purge-liquide-de-frein': '34',
  embrayage: '24',
  'diagnostic-securite': '29',
  'renovation-optique': '32',
  'freinage-disques-et-plaquettes': '11',
  'renovation-carrosserie': '31',
};

export default icons;
