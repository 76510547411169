import type { MenuPromotionBannerModel } from 'src/models/menu/menuPromotionBanner.model.';

import { performServerAPICall } from 'src/utils/server/ServerAPIUtils';

export const getMenuPromotionBanner = async (idNetworkBase?: string) => {
  return performServerAPICall<MenuPromotionBannerModel>({
    url: 'promotion-banners/first',
    params: {
      idNetworkBase,
    },
  });
};
