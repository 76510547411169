import type { FunctionComponent, MutableRefObject } from 'react';
import React, { useState } from 'react';

import { Link, Image, Icon } from 'src/components/shared';
import type { IconType } from 'src/components/shared/Icon/types';

import styles from './HeaderMenu.module.scss';
import { gtmHeaderMenuItemClick } from './HeaderMenuGuide';

import type { MenuReseaux } from 'src/models/menu/menu.model';

import { mergeClassNames } from 'src/utils/ReactUtils';

type HeaderMenuReseauProps = {
  items: MenuReseaux;
  subMenuRef: MutableRefObject<HTMLDivElement | null>;
};

const HeaderMenuReseau: FunctionComponent<HeaderMenuReseauProps> = ({
  items,
  subMenuRef,
}) => {
  const [menuActive, setMenuActive] = useState(-1);

  const renderCardsList = items.map((item, index) => {
    return (
      <li key={index} className={styles.card}>
        <figure
          className={`${styles.cardContainer}`}
          onClick={() => setMenuActive(index)}
        >
          <div className={`${styles.pictureWrapper}`}>
            <Image
              width={263}
              height={133}
              src={item.image}
              alt={item.alt}
              className={styles.cardPicture}
            />
          </div>
          <div className={styles.cardCat}>
            <Icon
              name={item.icon as IconType}
              width={45}
              height={45}
              className={styles.cardIcon}
            />
            <figcaption className={styles.cardTitle}>{item.label}</figcaption>
          </div>
          <Icon
            name="fleche-d"
            height={16}
            width={16}
            className={`d-lg-none ${styles.itemArrow}`}
          />
        </figure>
        <p className={`d-none d-lg-block ${styles.cardDesc}`}>
          {item.description}
        </p>
        <div
          className={mergeClassNames([
            styles.cardSublist,
            menuActive === index && styles.cardSublistActive,
          ])}
        >
          <div className={`d-lg-none ${styles.cardHeader}`}>
            <Icon
              name="fleche-g"
              height={32}
              width={32}
              className={styles.itemArrow}
              onClick={() => setMenuActive(-1)}
            />
            <div className={'d-flex flex-column'}>
              <figure
                className={`${styles.cardContainer}`}
                onClick={() => setMenuActive(-1)}
              >
                <div className={`${styles.pictureWrapper}`}>
                  <Image
                    width={263}
                    height={133}
                    src={item.image}
                    alt={item.alt}
                    className={styles.cardPicture}
                  />
                </div>
                <div className={styles.cardCat}>
                  <Icon
                    name={item.icon as IconType}
                    width={45}
                    height={45}
                    className={styles.cardIcon}
                  />
                  <figcaption className={styles.cardTitle}>
                    {item.label}
                  </figcaption>
                </div>
              </figure>
              <p className={styles.cardDesc}>{item.description}</p>
            </div>
          </div>
          <ul className={styles.cardEntries}>
            {item.links.map(
              (link: { label: string; url: string }, i: number) => {
                return (
                  <li key={i} className={styles.cardEntry}>
                    <Link
                      className={styles.cardLink}
                      href={link.url}
                      onClick={() =>
                        gtmHeaderMenuItemClick(
                          'Notre réseau',
                          item.label,
                          link.label,
                        )
                      }
                    >
                      <Icon
                        name="fleche-d"
                        height={12}
                        width={12}
                        className={styles.cardPicto}
                        color={'#830008'}
                      />
                      <span className={styles.cardItem}>{link.label}</span>
                    </Link>
                  </li>
                );
              },
            )}
          </ul>
        </div>
      </li>
    );
  });

  return (
    <div className={styles.itemContainer} ref={subMenuRef}>
      <ul className={styles.cards}>{renderCardsList}</ul>
    </div>
  );
};
export default HeaderMenuReseau;
