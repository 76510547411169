import { getGarageServicesData } from '../prestation';
import { getGarage } from './getGarage';

import { menuMapper } from 'src/models/menu/menu.model';

export const getGarageMenuData = async (
  garageSlug: string,
  idBaseNetwork: string,
) => {
  const [garageData, garage] = await Promise.all([
    getGarageServicesData(garageSlug),
    getGarage(garageSlug),
  ]);

  const menuData = garageData.menu;

  const mechanical = menuData
    .find((item) => item.id === 'mechanical')
    ?.children.map((item, index) => menuMapper(item, index, garageSlug));
  const bodywork = menuData
    .find((item) => item.id === 'bodywork')
    ?.children.map((item, index) => menuMapper(item, index, garageSlug));
  const tire = menuData
    .find((item) => item.id === 'tire')
    ?.children.map((item, index) => menuMapper(item, index, garageSlug));

  const firstLevelMenu = (
    [
      mechanical && mechanical.length > 0
        ? {
            id: 'entretien_voiture',
            title: 'Entretenir votre voiture',
            icon: 'm-reparation',
            type: 'entretien_voiture',
            child: mechanical,
            banner: null,
            url: null,
          }
        : null,
      bodywork && bodywork.length > 0
        ? {
            id: 'reparation_carousserie',
            title: 'Réparation carrosserie',
            icon: 'carrosserie',
            type: 'reparation_carousserie',
            child: bodywork,
            banner: null,
            url: null,
          }
        : null,
      tire && tire.length > 0
        ? {
            id: 'changer_pneus',
            title: 'Changer vos pneus',
            icon: 'm-pneu',
            child: null,
            url: `/garage/${garageSlug}/prestation/pneumatiques`,
            tagElements: {
              event: 'eventGA',
              eventCategory: 'menu',
              eventAction: 'Changer vos pneus',
            },
          }
        : null,
      garage?.hasVehicle
        ? {
            id: 'voiture_occasion',
            title: "Nos voitures d'occasion",
            icon: 'm-occasion',
            child: null,
            url: `/annonces-vehicules?id_network_base=${idBaseNetwork}`,
            tagElements: {
              event: 'eventGA',
              eventCategory: 'menu',
              eventAction: "Nos voitures d'occasion",
            },
          }
        : null,
      {
        id: 'm-emploi',
        title: 'Recrutement',
        icon: 'm-emploi',
        child: null,
        url: 'https://taleez.com/careers/reseauad',
      },
    ] as const
  ).filter(Boolean);

  return firstLevelMenu as NonNullable<(typeof firstLevelMenu)[number]>[];
};
