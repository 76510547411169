import type { FunctionComponent } from 'react';

import CMSContent from '../CMSContent/CMSContent';
import styles from './Marquee.module.scss';

export type MarqueeProps = {
  list: string[];
};

const Marquee: FunctionComponent<MarqueeProps> = ({ list }) => {
  return (
    <div className={styles.marqueeContainer}>
      <div className={styles.marqueeArea}>
        {list.map((item: string, index: number) => {
          return (
            <div key={index} className={styles.marqueeItem}>
              <CMSContent content={item} />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Marquee;
