import type { PrestationModel } from 'src/models';

import { performServerAPICall } from 'src/utils/server/ServerAPIUtils';

export const getPrestationSlugs = async () => {
  return performServerAPICall<PrestationModel[]>({
    url: 'cms/car-services',
    params: {
      items_per_page: 10000,
    },
  });
};
