import type { GarageServiceModel } from 'src/models/prestation/GarageService.model';

import { performServerAPICall } from 'src/utils/server/ServerAPIUtils';

export const getGaragePrestationDetails = async (
  garageSlug: string,
  serviceSlug: string,
) => {
  return performServerAPICall<GarageServiceModel>({
    url: `cms/garages/${garageSlug}/car-service-pages/${serviceSlug}`,
  });
};
