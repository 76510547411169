import type { CarServiceBlock } from 'src/models';

import { performServerAPICall } from 'src/utils/server/ServerAPIUtils';

export const getGarageServices = async (
  slug: string,
  hasCmsBlock?: boolean,
) => {
  return performServerAPICall<CarServiceBlock[]>({
    url: `cms/garages/${slug}/car-service-families`,
    params: {
      itemsPerPage: 10000,
      ...(hasCmsBlock && { hasCmsBlock }),
    },
  });
};
