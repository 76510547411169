import type { Site } from 'src/models';

import type { UseQueryConfig } from 'src/utils/client/ClientAPIUtils';
import { usePatchedSWR } from 'src/utils/client/ClientAPIUtils';

export type SiteQueryData = Site;
export type SiteQueryVariables = undefined;

export const useSite = (
  config?: UseQueryConfig<SiteQueryData, SiteQueryVariables>,
) =>
  usePatchedSWR<SiteQueryData>(
    {
      url: 'sites',
    },
    {
      refreshInterval: 120000,
      fallbackData: {
        isQuotationEnabled: false,
        borderEnabled: false,
        borderMessage: null,
      },
      revalidateOnMount: true,
      ...config,
    },
  );
