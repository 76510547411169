import type {
  AxiosRequestConfig,
  AxiosResponse,
  RawAxiosRequestHeaders,
} from 'axios';
import axios from 'axios';
import { camelizeKeys, decamelizeKeys } from 'humps';
import { stringify } from 'qs';

const cache: Record<string, unknown> = {};

type NetworkConfig<
  ParamsVariables extends Record<string, unknown>,
  DataVariables extends Record<string, unknown>,
> = {
  method?: AxiosRequestConfig['method'];
  url: string;
  params?: ParamsVariables;
  data?: DataVariables;
  noCache?: boolean;
  baseURL?: string;
};

const performCall = async <Data>({
  method = 'GET',
  url,
  params = {},
  data = {},
  noCache,
  baseURL = process.env.NEXT_PUBLIC_API_URL,
}: NetworkConfig<Record<string, unknown>, Record<string, unknown>>) => {
  const key = !noCache
    ? [
        method,
        url,
        ...(params
          ? Object.entries(params).map(
              (arg) => `${arg[0]}${JSON.stringify(arg[1])}`,
            )
          : []),
      ].join()
    : '';

  if (!noCache && cache[key]) {
    return cache[key] as AxiosResponse<Data>;
  }

  const instance = axios.create();

  // Axios middleware to convert all API responses to camelCase
  instance.interceptors.response.use((res) => {
    if (
      res.data &&
      res.headers &&
      res.headers['content-type'].includes('application/json')
    ) {
      res.data = camelizeKeys(res.data);
    }

    return res;
  });

  const res = await instance.request<Data>({
    baseURL,
    method,
    url,
    params,
    paramsSerializer: { serialize: (p) => stringify(p) },
    data: decamelizeKeys(data),
  });

  if (method === 'GET' && !noCache) {
    cache[key] = { data: res.data, headers: res.headers };
  }

  return {
    data: res.data,
    headers: JSON.parse(
      JSON.stringify(res.headers || {}),
    ) as RawAxiosRequestHeaders,
  };
};

export const performServerAPICall = async <Data>({
  method = 'GET',
  url,
  params,
  data,
  noCache,
  baseURL,
}: NetworkConfig<Record<string, unknown>, Record<string, unknown>>) => {
  const res = await performCall<Data>({
    method,
    url,
    params,
    data,
    noCache,
    baseURL,
  });

  return res.data;
};

export const performServerAPICallWithResponseHeaders = async <Data>({
  method = 'GET',
  url,
  params,
  noCache,
}: NetworkConfig<Record<string, unknown>, Record<string, unknown>>) => {
  const res = await performCall<Data>({ method, url, params, noCache });

  return {
    data: res.data,
    headers: res.headers,
  };
};
