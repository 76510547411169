export const menuReseaux = [
  {
    image: '/images/components/Header/services.jpg',
    alt: 'Nos services',
    icon: 'm-services',
    label: 'Nos services',
    description: `Dans un environnement auto en constante évolution, AD
    souhaite vous proposer toujours plus de services
    intelligents et simples pour vous faciliter la vie.`,
    links: [
      {
        label: "Contrat d'entretien AD",
        url: '/notre-reseau/accueil-nouveaux-services-notre-contrat-entretien',
      },
      {
        label: 'Véhicules de courtoisie',
        url: '/notre-reseau/accueil-ad-vous-propose-un-service-de-vehicule-de-courtoisie',
      },
      {
        label: 'Contrôle technique',
        url: '/notre-reseau/accueil-pack-controle-technique',
      },
      {
        label: "L'éco-entretien",
        url: '/notre-reseau/accueil-l-eco-entretien-ad',
      },
      {
        label: 'Carte grise en ligne',
        url: '/notre-reseau/accueil-faire-sa-carte-grise-en-ligne-en-2-minutes',
      },
    ],
  },
  {
    image: '/images/components/Header/guaranty.jpg',
    alt: 'Nos garanties',
    icon: 'm-garanties',
    label: 'Nos garanties',
    description: `Rien n'est plus sûr que de faire entretenir sa voiture chez
      l'un de nos 2000 adhérents. Qu'il s'agisse de carrosserie ou de mécanique,
      AD c'est la garantie qualité qu'il vous faut.`,
    links: [
      {
        label: 'Notre garantie carrosserie à vie',
        url: '/notre-reseau/garanties-garantie-carrosserie-a-vie',
      },
      {
        label: "Notre garantie pièces et main d'œuvre",
        url: '/notre-reseau/accueil-garanties-pieces-et-main-d-oeuvre',
      },
      {
        label: 'Notre charte de qualité',
        url: '/notre-reseau/accueil-charte-de-qualite',
      },
      {
        label: 'Vos avis aux centre de nos préoccupations',
        url: '/notre-reseau/accueil-vos-avis-aux-centre-de-nos-preoccupations',
      },
    ],
  },
  {
    image: '/images/components/Header/commitments.jpg',
    alt: 'Nos engagements',
    icon: 'm-engagement',
    label: 'Nos engagements',
    description: `AD c'est sûr, n'est pas qu'une signature. C'est une promesse.
      Le réseau AD s'engage pour votre sécurité, votre satisfaction et votre
      confiance.`,
    links: [
      {
        label: 'Découvrez le détail de nos engagements',
        url: '/nos-engagements',
      },
    ],
  },
  {
    image: '/images/components/Header/job-offers.jpg',
    alt: "Nos offres d'emploi",
    icon: 'm-emploi',
    label: "Nos offres d'emploi",
    description: `Les réparateurs AD sont des experts en mécanique et en
      carrosserie. Ils entretiennent et réparent votre véhicule
      peu importe sa marque, son modèle ou son âge.`,
    links: [
      {
        label: 'Voir toutes nos offres',
        url: 'https://taleez.com/careers/reseauad/',
      },
    ],
  },
] as const;
