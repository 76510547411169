import type { FunctionComponent } from 'react';
import React from 'react';

import styles from './CMSContent.module.scss';

import { getDataTestId, renderHTML } from 'src/utils/ReactUtils';

type CMSContentProps = {
  content: string;
  dataTestId?: string;
  noStyle?: boolean;
};

const CMSContent: FunctionComponent<CMSContentProps> = ({
  content,
  dataTestId,
  noStyle,
}) => {
  return (
    <div
      className={!noStyle ? styles.content : undefined}
      {...getDataTestId({ dataTestId })}
    >
      {renderHTML(content.replace(/&nbsp;/g, ''))}
    </div>
  );
};

export default CMSContent;
