import type { CityModel } from 'src/models/city.model';

import { performServerAPICall } from 'src/utils/server/ServerAPIUtils';

type topCityRequestPayload = {
  limit?: number;
  department?: string;
  region?: string;
  orderBy?: string;
  order?: string;
};

export const getTopCities = async ({
  limit,
  region,
  department,
  orderBy,
  order,
}: topCityRequestPayload = {}) => {
  const params = {
    ...(limit && { limit: limit }),
    ...(region && { region: region }),
    ...(department && { department: department }),
    ...(order && orderBy && { [`order[${orderBy}]`]: order }),
  };
  return performServerAPICall<CityModel[]>({
    url: 'garage-locator/top-cities',
    params,
  });
};
