import type { FunctionComponent } from 'react';
import React from 'react';

import Responsive from 'src/components/shared/Responsive/Responsive';

type MenuItemWrapperProps = {
  handleShowMenu: () => void;
  handleHideMenu: () => void;
  className?: string;
};
const MenuItemWrapper: FunctionComponent<MenuItemWrapperProps> = (props) => {
  const { handleShowMenu, handleHideMenu, children } = props;

  return (
    <>
      <Responsive isOrLarger={'largeDesktop'} cloneChild>
        <li
          {...props}
          onMouseEnter={handleShowMenu}
          onMouseLeave={handleHideMenu}
        >
          {children}
        </li>
      </Responsive>

      <Responsive isOrSmaller={'mediumDesktop'} cloneChild>
        <li {...props}>{children}</li>
      </Responsive>
    </>
  );
};

export default MenuItemWrapper;
