import type { AdviceCategory } from 'src/models';

import { performServerAPICall } from 'src/utils/server/ServerAPIUtils';

export const getAdviceCategories = async (
  itemsPerPage = 99,
  withChilds = false,
  orderBy = 'order[linkLabel]',
  order = 'asc',
  familyId?: number,
) => {
  const params = {
    ...(itemsPerPage && { itemsPerPage }),
    [`order[${orderBy}]`]: order,
    ...(withChilds ? { 'groups[]': 'withChildren' } : {}),
    ...(familyId && { familyId }),
  };

  return performServerAPICall<AdviceCategory[]>({
    url: 'cms/advice-categories',
    params,
  });
};
