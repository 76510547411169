import icons from './icons';
import type { MenuPrestationModel } from './menuPrestation.model';

import type { bannerReparation } from 'src/services/menu/bannerReparation';
import type { getMenuData } from 'src/services/menu/getMenuData';
import type { menuEspacePro } from 'src/services/menu/menuEspacePro';
import type { menuReseaux } from 'src/services/menu/menuReseaux';

export type TagElement = {
  event: string;
  eventCategory?: string;
  eventAction?: string;
};

export type MenuItem = Awaited<ReturnType<typeof getMenuData>>[number];
export type BannerReparation = typeof bannerReparation;
export type MenuReseaux = typeof menuReseaux;
export type MenuEspacePro = typeof menuEspacePro;

export type MenuChild = {
  label: string;
  slug: string;
};

export type MenuParentItem = {
  id: string;
  label: string;
  slug: string | null;
  children: MenuChild[];
};

export const menuMapper = (item: MenuChild, id: number, garageSlug?: string) =>
  ({
    id,
    title: item.label,
    targetUrl: `${garageSlug ? `/garage/${garageSlug}` : ''}/prestation/${
      item.slug
    }`,
    icon: `icon-famille-${icons[item.slug] ?? '00'}`,
  } as MenuPrestationModel);
