import type { FunctionComponent } from 'react';
import React from 'react';
import TagManager from 'react-gtm-module';
import type { AdviceCategory } from 'src/models';

import { Icon, Link } from 'src/components/shared';

import styles from './HeaderMenu.module.scss';

export const gtmHeaderMenuItemClick = (
  level1: string,
  level2?: string,
  level3?: string,
) => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'eventGA',
      eventCategory: 'menu',
      eventAction: level1,
      ...(level2 ? { eventLabel: level2 } : {}),
      ...(level3 ? { niveau3: level3 } : {}),
    },
  });
};

const MAX_GUIDES_ITEMS = 24;

type HeaderMenuGuideProps = {
  items: AdviceCategory[];
};

const HeaderMenuGuide: FunctionComponent<HeaderMenuGuideProps> = ({
  items,
}) => {
  const buildMenuGuide = (guide: AdviceCategory) => {
    return (
      <li key={guide.id} className={styles.listItem}>
        <Link
          className={styles.listLink}
          href={guide.url}
          onClick={() => {
            gtmHeaderMenuItemClick(
              'Notre guide conseil',
              guide.linkLabel ?? guide.title,
            );
          }}
          title={guide.linkLabel ?? guide.title}
        >
          <span className={styles.listEntry}>
            {guide.linkLabel ?? guide.title}
          </span>
          <Icon
            name="fleche-d"
            height={12}
            width={12}
            className={`d-lg-none ${styles.adviceIcon}`}
          />
        </Link>
      </li>
    );
  };

  const guidesList = [
    ...items
      .slice(0, MAX_GUIDES_ITEMS - (items.length > MAX_GUIDES_ITEMS ? 1 : 0))
      .map((prestationEntretienItem) =>
        buildMenuGuide(prestationEntretienItem),
      ),
    buildMenuGuide({
      id: 999,
      linkLabel: 'Voir toutes nos fiches',
      title: 'Voir toutes nos fiches',
      url: '/guides/guide-conseil',
    } as AdviceCategory),
  ].filter(Boolean);

  return (
    <div className={styles.itemContainer}>
      <div>
        <div className={styles.itemTitle}>Découvrez nos conseils d’experts</div>
        <ul className={styles.guidesList}>{guidesList}</ul>
      </div>
    </div>
  );
};
export default HeaderMenuGuide;
