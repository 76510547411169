import { useRouter } from 'next/router';
import type { FunctionComponent } from 'react';
import React, { useEffect, useRef, useState } from 'react';
import type { Garage } from 'src/models';

import { useQuotationEngineContext } from 'src/components/layouts/PublicLayout/components/QuotationEngine/QuotationEngineContext';
import { Link, Image, InlineCritizr, Icon } from 'src/components/shared';
import CMSContent from 'src/components/shared/CMSContent/CMSContent';
import Marquee from 'src/components/shared/Marquee';
import Responsive from 'src/components/shared/Responsive/Responsive';
import { Container } from 'src/components/ui';

import styles from './Header.module.scss';
import HeaderMenu, { gtmGaragesClick } from './HeaderMenu/HeaderMenu';

import { useSite } from 'src/services/site/useSite';

import type { FeedbackStatsModel } from 'src/models/feedback/feedbackStats.model';
import type { MenuItem } from 'src/models/menu/menu.model';

import { mergeClassNames, usePageType } from 'src/utils/ReactUtils';
import { getWindowWidth } from 'src/utils/WindowUtils';

const BREAKPOINT = 1366;
const topText = 'GARAGE ET CARROSSERIE : ENTRETIEN & RÉPARATION AUTO';

export type HeaderProps = {
  data: MenuItem[];
  garage?: Garage | null;
  feedbackStats: FeedbackStatsModel;
};

const Header: FunctionComponent<HeaderProps> = ({
  data,
  garage,
  feedbackStats,
}) => {
  const { openQuotationEngine } = useQuotationEngineContext();
  const [menuVisible, setMenuVisible] = useState(false);
  const [forceBlur, setForceBlur] = useState(false);
  const pageType = usePageType();
  const { data: site } = useSite();
  const headerRef = useRef<HTMLDivElement>(null);
  const isPro = pageType === 'pro';
  const router = useRouter();

  const isNewsMessageEnabled = site?.borderEnabled && !isPro;

  useEffect(() => {
    setMenuVisible(false);
  }, [router.asPath]);

  useEffect(() => {
    window.addEventListener('resize', () => {
      if (getWindowWidth() >= BREAKPOINT) {
        setMenuVisible(false);
      }
    });
  }, []);

  const handleToggleNav = () => {
    setMenuVisible((prev) => !prev);
  };

  const openSearchEngine = () => {
    setMenuVisible(false);
    openQuotationEngine({ garage: garage || undefined });
  };

  const renderTypeEnseigne = () => {
    if (garage?.typeEnseigne.includes('EXPERT')) {
      return (
        <span className={styles.garageWrapper}>
          <span className={styles.garageType}>
            {garage?.typeEnseigne.replace('EXPERT', '')}
          </span>
          <span className={styles.garageExpert}>
            <Image
              layout={'fill'}
              src={'/images/components/Header/logo_expert.png'}
              alt={garage.name}
            />
          </span>
        </span>
      );
    }
    return <div className={styles.garageType}>{garage?.typeEnseigne}</div>;
  };

  return (
    <>
      <div
        className={mergeClassNames([
          styles.blur,
          (menuVisible || forceBlur) && styles.visible,
        ])}
      />
      {!isPro && (
        <div
          className={
            isNewsMessageEnabled
              ? styles.fakeHeaderWithInfoBanner
              : styles.fakeHeader
          }
        />
      )}
      {isNewsMessageEnabled && (
        <div className={styles.stickyInfoBanner}>
          <Responsive isNot="mobile">
            <CMSContent content={site?.borderMessage || ''} />
          </Responsive>
          <Responsive is="mobile">
            <Marquee list={[site?.borderMessage || '']} />
          </Responsive>
        </div>
      )}

      <header
        className={mergeClassNames([
          styles.header,
          menuVisible && styles.headerOpen,
          garage && styles.headerGarage,
          isNewsMessageEnabled && styles.headerWithBanner,
        ])}
        ref={headerRef}
      >
        <Container fluid>
          <div className={styles.top}>
            <div className={styles.topContainer}>
              <div className={styles.mainLogo}>
                <Link
                  href="/"
                  className={styles.homeLink}
                  title="entretien et réparation auto multimarques"
                >
                  <Image
                    layout="fill"
                    src={
                      garage
                        ? '/images/common/brand-logo-no-txt.png'
                        : '/images/components/Header/logo-marque.svg'
                    }
                    alt="logo autodistribution : entretien et réparation auto multimarques"
                    priority
                  />
                </Link>
              </div>
              {garage && (
                <div className={styles.garageInfos}>
                  {renderTypeEnseigne()}
                  <span className={styles.garageName}>{garage.name}</span>
                </div>
              )}
            </div>
            <div className={styles.topWrapper}>
              {!garage ? (
                <>
                  {pageType === 'homepage' ? (
                    <h1
                      className={styles.topTxt}
                      data-testid="menu_item_entretien"
                    >
                      {topText}
                    </h1>
                  ) : (
                    <div
                      className={styles.topTxt}
                      data-testid="menu_item_entretien"
                    >
                      {topText}
                    </div>
                  )}
                  {isPro ? (
                    <Link
                      href="/"
                      className="d-block"
                      title="Revenir sur le site pour le particuliers"
                    >
                      <Icon
                        name="tarifs-web"
                        className={styles.locatorIcon}
                        width={32}
                        height={32}
                      />
                      <span className={styles.topTxt}>Espace particuliers</span>
                    </Link>
                  ) : (
                    <Link
                      href="/garage-auto"
                      className="d-none d-md-block"
                      title="Trouvez votre garage automobile"
                      onClick={() => {
                        gtmGaragesClick();
                      }}
                      data-testid="menu_item_reparateur_auto"
                    >
                      <Icon
                        name="recherche-garage"
                        className={styles.locatorIcon}
                        width={32}
                        height={32}
                      />
                      <span className={styles.topTxt}>Nos garages</span>
                    </Link>
                  )}
                </>
              ) : (
                <>
                  <h2
                    className={styles.topTxt}
                    data-testid="menu_item_entretien"
                  >
                    {topText}
                  </h2>
                  {feedbackStats.countReview > 0 && (
                    <InlineCritizr
                      className={styles.garageFeedBacks}
                      txtRatingColor={'Brown'}
                      averageRating={feedbackStats.reviewNote}
                      actorId={garage.garIdNetwork}
                      totalRatings={feedbackStats.countReview}
                    />
                  )}
                </>
              )}
              {!isPro && (
                <span
                  data-testid={`header_menu_burger_btn`}
                  className={mergeClassNames([styles.burgerIcon, 'd-lg-none'])}
                  onClick={handleToggleNav}
                >
                  {menuVisible ? (
                    <Icon
                      name="croix"
                      className={styles.burgerIconClose}
                      width={32}
                      height={32}
                    />
                  ) : (
                    <Icon name="mob-burger" width={32} height={32} />
                  )}
                </span>
              )}
            </div>
          </div>
        </Container>

        {!isPro && (
          <HeaderMenu
            isNewsBannerEnabled={isNewsMessageEnabled}
            isOpen={menuVisible}
            openSearchEngine={openSearchEngine}
            data={data}
            feedBackStats={feedbackStats}
            setForceBlur={setForceBlur}
            menuVisible={menuVisible}
            garage={garage}
          />
        )}
      </header>
    </>
  );
};

export default Header;
